body {
    min-height: 100vh;
}

.container-fluid {
    max-width: 1700px;
}

.btn-primary {
    width: 100%;
    max-width: 270px;
    background-color: #d8b802;
    border-color: #d8b802;
    padding: 10px 15px;

    &:hover,
    &:focus {
        background-color: #b49900;
        border-color: #b49900;
        outline: none;
        box-shadow: none !important;
    }
}

.btn-white {
    width: 100%;
    max-width: 270px;
    background-color: #fff;
    border-color: #000;
    padding: 10px 15px;

    &:hover,
    &:focus {
        background-color: #000;
        border-color: #000;
        color: #fff;
        outline: none;
        box-shadow: none !important;
    }
}

.btn-full {
    width: 100%;
    max-width: 100%;
}

.btn-sm {
    padding: 5px;
}


.rounded {
    border-radius: 8px !important;
}


.modal-title.text-center {
    width: calc(100% - 1.25rem);
}


.form-control {
    background-color: #ffffff;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
}

.form-select {
    background-color: #ffffff;
}


.admin-view {
    #meta-pistopoiisi-thanatou.d-none {
        display: block !important;
    }
}
