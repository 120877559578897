// Body
$body-bg: #f7f8f8;

// Typography
$font-family-sans-serif: 'Roboto Condensed',
sans-serif;
$font-size-base: 1.2rem;
$line-height-base: 1.2;

// $font-size-22: 21.39px;

//Colors
$light : #ffffff;


//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1440px,
    xxl: 1600px);

//
//  WHEN ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include when-above(sm) {}
@mixin when-more($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    }

    @else {

        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}


//
//  WHEN BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include when-below(sm) {}
@mixin when-less($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    }

    @else {

        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}


//
//  WHEN BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include when-between(sm, md) {}
@mixin when-between($lower, $upper) {

    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    }

    @else {

        // If lower breakpoint is invalid.
        @if (map-has-key($breakpoints, $lower)==false) {

            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map-has-key($breakpoints, $upper)==false) {

            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}
