.select2-container--bootstrap-5 .select2-selection {
    background-color: #ffffff;
}

.select2-container--bootstrap-5.select2-container--focus .select2-selection,
.select2-container--bootstrap-5.select2-container--open .select2-selection {
    box-shadow: none;
}

.select2-container--bootstrap-5 {
    max-width: 100%;
}
