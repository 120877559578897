// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Dropzone
.dropzone {
    margin-bottom: 20px;
    min-height: auto;
}

@import '../../node_modules/vanillajs-datepicker/sass/datepicker.scss';

// Select 2
@import "~select2/dist/css/select2.css";
@import "../../node_modules/select2-bootstrap-5-theme/src/include-all";

// body {
//     font-size: $font-size-22 !important;
// }


@import '__global';
@import '_guest';

@import '_datatables-overrides';
@import '_select2-overrides';
@import '_header';
@import '_footer';
@import '_tables';
@import '_incident';
@import '_rating';
