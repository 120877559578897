.dataTables_length {
    @include when-less(sm) {
        margin-bottom: 10px;
    }
}

.dataTables_info {
    @include when-less(sm) {
        margin-bottom: 10px;
    }
}

.completed-row th,
.completed-row td {
    // background-color: #aaf9aa !important;
}

.completed-row--0 {

    th,
    td {
        // background-color: #aaf9aa !important;
    }
}

.completed-row--1 {

    & .accordion-item,
    & .accordion-button,
    th,
    td {
        background-color: #bdbdbd !important;
    }
}

.completed-row--2 {

    & .accordion-item,
    & .accordion-button,
    th,
    td {
        background-color: #D9D4E8 !important;
    }
}

.completed-row--3 {

    & .accordion-item,
    & .accordion-button,
    th,
    td {
        background-color: #B0D6C2 !important;
    }
}

.completed-row--4 {

    & .accordion-item,
    & .accordion-button,
    th,
    td {
        background-color: #A5C8D5 !important;
    }
}

.completed-row--5 {

    & .accordion-item,
    & .accordion-button,
    th,
    td {
        background-color: #E4BBE8 !important;
    }
}


.dt-buttons {
    margin-bottom: 25px;
}


.incidents-table .btn-primary {
    background-color: #FED531;

    &:hover {
        background-color: #b49900;
    }
}
