.incident {

    &-addedit {
        label {
            margin-bottom: 5px;
        }


        @include when-less(md) {
            .accordion-body {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}


.disabled {
    background-color: #cbcbcb;

    &>* {
        pointer-events: none;
    }
}


.input-with-time {
    display: flex;
}


.accordion-header {
    position: relative;
}

span.ml-auto.se-ekkremotita {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    color: red;
    z-index: 12;
    pointer-events: none;
}

.extra-tab-info {
    color: red;
    font-size: 60%;

    span {
        border: 2px solid red;
        padding: 2px;
        margin: 0 4px;
    }
}
