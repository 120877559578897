.table {
    overflow: hidden;
    width: 100% !important;

    th {
        background-color: #e6e3e3;
    }

    td {
        background-color: #ffffff;
    }
}



.accordion {
    &-item {
        margin-bottom: 32px;
        background-color: #FFF;
        border: 0px solid rgba(0, 0, 0, 0.125);
    }

    &-body {
        padding: 3rem 1.25rem;
    }

    &-button {
        outline: none;
        box-shadow: none;
        color: #231f20;
        background-color: #FFF;
        font-size: 27px;
        font-weight: bold;

        span {
            width: calc(100% - 1.25rem);
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &-button:not(.collapsed) {
        color: #231f20;
        background-color: #FFF;
        font-size: 27px;
        font-weight: bold;
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
        text-align: center;
    }
}
