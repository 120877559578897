.guest-page {
    min-height: 100vh;

    main {
        margin-top: 100px;

        @include when-less(sm) {
            margin-top: 50px;
        }
    }
}

.guest-logo {
    padding: 18px;
    background-color: $light;
    width: 230px;
    display: block;
    margin: 0 auto;
}

.select-hospital {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;

    &>div {
        padding: 50px;
    }

    label {
        margin-bottom: 17px;
    }

    select.form-select {
        border: 1px solid #f2f2f2;
        background-color: #f2f2f2;
        margin-bottom: 17px;
    }

    @include when-less(sm) {
        max-width: 320px;

        &>div {
            padding: 30px;
        }

        label {
            margin-bottom: 13px;
        }

        select.form-select {
            margin-bottom: 13px;
        }

    }

}

.login {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;

    &>.shadow {
        padding: 30px;
    }

    label {
        margin-bottom: 8px;
    }

    @include when-less(sm) {
        max-width: 320px;

        &>div {
            padding: 30px;
        }

        label {
            margin-bottom: 13px;
        }
    }
}
