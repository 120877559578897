.header {

    &__inner-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__page-title {
        padding-bottom: 60px;
        font-weight: bold;
        font-size: 36px;
    }

    &__actions {
        padding-bottom: 60px;

        a {
            text-decoration: none;
            margin-left: 30px;
        }

        a.login_log{
            display: inline-block;
            padding: 10px;
            background-color: gray;
            color: $light;

            &.green{
                background-color: rgb(41, 143, 41);
                pointer-events: none;
            }
        }
    }

    .logo {
        padding: 18px;
        background-color: $light;
        width: 230px;
        display: inline-block;
    }

    &::before {
        content: '';
        width: 100%;
        height: 165px;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    @include when-less(md) {
        &__inner-container {
            flex-direction: column;
        }

        &__page-title {
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 25px;
        }

        &__actions {
            padding-bottom: 0px;

            a {
                margin-left: 15px;
            }
        }
    }
}
