.footer {
    &__actions {
        display: flex;
        justify-content: flex-end;

        a {
            margin-left: 5px;
        }

        @include when-less(md) {
            flex-wrap: wrap;

            a {
                margin-bottom: 15px;
            }
        }
    }

    &__copyright {
        margin-top: 25px;
        margin-bottom: 25px;
        padding-top: 25px;
        text-align: center;
        border-top: 1px solid #707070;
    }
}
